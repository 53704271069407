import React, { useRef } from "react";
import Project from "components/Project";
import { motion, useAnimation, useTransform, useSpring } from "framer-motion";

const Projects = ({ projects, handleHover }) => {
  return (
    <div onHoverEnd={() => handleHover(20)} className='projects-wrapper'>
      {projects.map((project, index) => {
        return (
          <Project
            index={index}
            handleHover={handleHover}
            key={project.name}
            meta={project}
          />
        );
      })}
    </div>
  );
};

export default Projects;
