import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "gatsby";

const Project = ({ meta, handleHover, index }) => {


  return (
    <Link to={`/${meta.name.split(' ').join('-').toLowerCase()}`}>
      <motion.div
        onHoverStart={() => handleHover(index)}
        onHoverEnd={() => handleHover(20)}
        className='project-wrapper'
      >
        <h2>{meta.name === "Onze Metres Carres" ? "Onze Mètres Carrés" : meta.name}</h2>
        <p>{meta.skills.join(", ")}</p>
        <p>{meta.date}</p>

        {/* <AnimatePresence>
          {isHovered ? (
            <motion.img
              magicId="pImage"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{
                ease: "circOut",
                duration: 0.01
              }}
              src={`/images/${meta.name.split(' ').join('-').toLowerCase()}/${meta.name.split(' ').join('-').toLowerCase()}_highlight.jpg`}
              alt={`${meta.name} highlight image`}
            />
          ) : null}
        </AnimatePresence> */}
      </motion.div>
    </Link>
  );
};

export default Project;
