import React, { useState } from "react"
import Helmet from "react-helmet"

import Layout from "components/Layout"
import Container from "components/Container"

import Projects from "../components/Projects"

import { motion, useSpring } from "framer-motion"

const IndexPage = () => {
    let springSettings = { damping: 500, stiffness: 100, mass: 1 }
    let mouseX = useSpring(0, springSettings)
    let mouseY = useSpring(0, springSettings)

    const [projects, setProjects] = useState([
        {
            name: "Antiprocess",
            skills: ["Branding", "Design", "Creative Direction"],
            date: "2017-2020",
        },
        {
            name: "Jean-Paul Gaultier",
            skills: ["UX", "UI Design", "Motion"],
            date: "2020",
        },
        {
            name: "Antipode",
            skills: ["UI Design", "Development"],
            date: "2020",
        },
        {
            name: "Onze Metres Carres",
            skills: ["UX", "UI Design"],
            date: "2019",
        },
        {
            name: "NP Sans",
            skills: ["Font Design"],
            date: "2018",
        },
    ])

    const [hoveredProject, setHoveredProject] = useState(null)

    console.log(projects[0].name.split(" ").join("-").toLowerCase())

    const handleHoveredProject = (index) => {
        if (index < 6) {
            const imagePath = `/images/${projects[index].name
                .split(" ")
                .join("-")
                .toLowerCase()}/${projects[index].name
                .split(" ")
                .join("-")
                .toLowerCase()}_highlight.jpg`
            setHoveredProject(imagePath)
        } else {
            setHoveredProject('/images/null.jpg')
        }
    }

    const strictEquals = (a, b) => {
        if (Object.is(a, b)) {
            if (isNaN(a) || isNaN(b)) {
                return false
            } else {
                return true
            }
        } else {
            if (a <= b && b <= a) {
                return true
            } else {
                return false
            }
        }
    }

    // console.log(hoveredProject);

    return (
        <div
            className='mouse-wrapper'
            pageName='home'
            onMouseMove={function (event, info) {
                mouseX.set(event.clientX + 16)
                mouseY.set(event.clientY + 16)
            }}
        >
            <motion.div className='image-wrapper'>
                <motion.img
                    x={mouseX}
                    y={mouseY}
                    // magicId="project-image"
                    //  initial={{scale: 1.5}}
                    //  animate={{y: 0, scale: 1}}
                    //  exit={{ scale: 1.5, transition: { type: "spring", damping: 100, stiffness: 300, mass: 0.5}}}
                    transition={{
                        type: "spring",
                        damping: 200,
                        stiffness: 100,
                        mass: 3,
                        velocity: 0,
                    }}
                    src={hoveredProject}
                    alt=''
                />
                {console.log(strictEquals(0, 1))}
            </motion.div>
            <Layout pageName='home'>
                <Helmet>
                    <title>Antoine Venco</title>
                </Helmet>
                <Container>
                    <h2 className='projects-header'>✹ Selected Projects ✹ </h2>
                    <Projects
                        handleHover={handleHoveredProject}
                        projects={projects}
                    />
                </Container>
            </Layout>
        </div>
    )
}

export default IndexPage
